.cat_single_card_header{
  padding: 0;
  display: flex;
  align-items: center;
  ion-img{
    width: 30px;
    margin: 0 10px 0 20px;
  }
}
.cat_single_card_subheader{
  padding: 20px 25px 20px 0;
  border-bottom: 1px solid #F3F5F0;
  @media (max-width: 767px) {
    ion-row ion-col:last-of-type div{
      justify-content: start !important;
    }
  }
}
.card_content{
  margin-left: 60px;
  text-align: left;
  padding-left: 0!important;
}
.card_footer{
  margin-left: 60px;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 10px 0;
  border-top: 1px solid #F3F5F0;
}