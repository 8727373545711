/* -----------------------------------------------------
	NAVBAR POPOVER
----------------------------------------------------- */

.content {
  display: flex;
  align-items: center;
  gap: 60px;
  padding: 35px;
  color: var(--sad-purple);

  h3 {
    margin-bottom: 20px;
    margin-top: 0;
  }
}

.linksContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  a {
    color: var(--sad-purple);

  }
}

@media (max-width: 767px) {
  .content {
    padding: 15px;
  }

  .content {
    h3 {
      margin-bottom: 10px;
    }
  }

  .linksContainer {
    gap: 10px;
  }
}