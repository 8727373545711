ion-card-header{
  padding: 0;
  ion-avatar{
    margin-left: auto;
    position: relative;
    width: 120px;
    height: 120px;
    @media (min-width: 768px) {
      width: 160px;
      height: 160px;
    }
    .edit_image_button{
      border-radius: 50%;
      border: 5px solid white;
      height: 50px;
      width: 50px;
      background: var(--ion-color-primary);
      border: 3px solid #FFFFFF;
      margin: auto;
      position: absolute;
      left: 72px;
      top: 72px;
      @media (min-width: 768px) {
        left: 112px;
        top: 112px;
      }
      z-index: 10;
      ion-icon{
        color: #FFFFFF;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      }
      input{
        width: 50px;
        height: 50px;
        margin-left: -200px;
        opacity: 0;
      }
      label{
        display: block;
        height: 100%;
        font-size: 21px;
      }
    }
  }
  @media (max-width: 767px) {
    ion-avatar{
      margin: 0 auto;
    }
  }
}
ion-radio-group{
  ion-col:first-child ion-item{
    margin-right: 5px;
  }
  ion-col:last-child ion-item{
    margin-left: 5px;
  }
  @media (max-width: 767px) {
    ion-col ion-item{
      margin: 5px !important;
    }
  }
}
.input_radio_wrapper{
  @media (min-width: 768px) {
    ion-radio{
      margin: 21px 10px 21px 0 !important;
    }
  }
}
.card_footer{
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.card_content{
  padding: 25px 0;
  padding-top: 25px !important;
  ion-col{
    padding: 0 !important;
  }
  @media (max-width: 991px) {
    padding-top: 0 !important;
    margin-top: -30px;
  }
}

.radio_group{
  @media (max-width: 767px) {
    ion-item{
      margin: 5px !important;
    }
  }
}

.avatar_loading{
  position: relative;
  &::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(102, 102, 102, 0.5);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 50%;
  }
  &::before{
    content: "";
    position: absolute;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 3;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='L9' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'%0AviewBox='0 0 100 100' enable-background='new 0 0 0 0' xml:space='preserve'%3E%3Cpath fill='%23fff' d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'%3E%3CanimateTransform attributeName='transform' attributeType='XML' type='rotate' dur='1s' from='0 50 50' to='360 50 50' repeatCount='indefinite' /%3E%3C/path%3E%3C/svg%3E");
  }
  .edit_image_button{
    background: #CACBE4;
    pointer-events: none;
  }
}