ion-card-header{
  padding: 0;
}
.card_footer{
  display: flex;
  justify-content: center;
  padding: 10px 10px 10px 0;
}
.card_content{
  padding: 0;
  ion-col{
    padding: 0 !important;
  }
}
.alert{
  margin-top: 0 !important;
  margin-bottom: 10px !important;
}

.hidden {
  display: none;
}

.label_avatar{
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  ion-avatar{
    width: 30px;
    height: 30px;
  }
}

.slider_wrapper{
  max-width: 100%;
  ion-label{
    white-space: pre-wrap !important;
  }
}

.out_form{
  padding-left: 16px;
  padding-right: 16px;
}