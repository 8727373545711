.card_cat_filters{
  box-shadow: 0px 15px 100px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 10px 20px;
  @media (max-width: 767px) {
    margin: 0;
    padding: 10px 10px;
    display: block;
  }
  ion-button.active{
    &::part(native){
      color: var(--ion-color-primary) !important;
    }
  }
  ion-select{
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    min-height: 30px;
  }
  ion-icon{
    font-size: 24px;
  }
}

ion-select::part(icon){
  display: none !important;
}