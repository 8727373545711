ion-card-header{
  padding: 0;
}
.card_footer{
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
  &.card_footer_fixed{
    padding-left: 10px;
    padding-right: 10px;
  }
}
.card_content{
  padding: 0 0 25px;
  ion-col{
    padding: 0 !important;
  }
}

@media (max-width: 767px) {
  .card_content{
    padding: 0;
  }
}