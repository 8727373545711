
.card_action{
  box-shadow: none;
  background-color: rgba(91, 52, 188, 0.05);
  padding: 70px 96px;
  ion-card-content{
    padding: 0 !important;
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    padding: 25px 20px 15px;
    margin: 20px 5px 0;
  }
}
.content_wrapper{
  flex: 1 1 auto !important;
  p{
    margin-bottom: 15px;
  }
  ion-button{
    margin: 0;
    margin-inline: 0;
  }
  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
}
.icon_wrapper{
  display: block;
  background-color: #FFFFFF;
  min-width: 65px;
  min-height: 65px;
  border-radius: 50%;
  position: relative;
  margin-right: 30px;
  ion-icon{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
  }
  @media (max-width: 767px) {
    min-width: 40px;
    min-height: 40px;
    margin-right: 15px;
    ion-icon{
      font-size: 15px;
    }
  }
}
