/* -----------------------------------------------------
	CAT SINGLE PAGE
----------------------------------------------------- */
ion-grid{
  padding-bottom: 0;
}
.cat_single{
  .cat_single_header{
    margin-top: 20px;
    @media (max-width: 550px) {
      margin-top: 0;
    }
    ion-col{
      padding-bottom: 0;
    }
    .buttons_header_top{
      margin-top: 55px;
      ion-button:hover{
        ion-icon{
          --ion-color-dark: --ion-color-secondary !important;
        }
      }
    }
    ion-avatar{
      margin: 0 auto;
      width: 120px;
      height: 120px;
      margin-top: -40px !important;
    }
    h1{
      margin-bottom: 0;
    }
    p{
      margin-top: 5px;
      margin-bottom: 40px;
    }
    .cat_single_nav{
      margin: 0;
      ul {
        margin: 0;
        padding-left: 0;
        padding-bottom: 13px;
        list-style-type: none;
        li{
          display: inline;
          a{
            color: var(--medium-grey);
            display: inline;
            padding: 20px 20px 14px;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            text-transform: uppercase;
            transition: border-color .15s ease-in-out, color .15s ease-in-out;
            border-bottom: 1px solid var(--light-grey);
            &.active_item,
            &:hover{
              border-color: var(--ion-color-primary);
              color: var(--ion-color-primary);
            }
          }
        }
      }
    }
  }
  .cat_single_profile_detail{
    h3{
      margin-top: 0px;
    }
    p{
      margin-top: 0;
    }
  }
  .alert_empty_data{
    background-color: rgba(91, 52, 188, 0.1);
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -10px;
    padding: 0 20px;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      padding: 10px 20px;
      margin-left: 5px;
      margin-right: 5px;
      p{
        text-align: center;
      }
    }
  }
  .cat_detail_information_card{
    box-shadow: none;
    border-radius: 0 !important;
    padding: 40px 20px;
    h3, 
    h4,
    p{
      text-align: center;
    }
    @media (max-width: 767px) {
      padding: 10px;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 5px;
      h3, 
      h4,
      p{
        text-align: left;
        margin-top: 0;
        margin-bottom: 0;
      }
      h3{
        margin-bottom: 15px;
      }  
    }
  }
  .cat_single_greysection{
    background-color: rgba(243, 245, 240, 0.5);
    padding: 60px 5px 40px;
    margin: 0 -5px;
  }
  @media (max-width: 767px) {
    .cat_single_header{
      .buttons_header_top{
        margin-top: 30px;
      }
      ion-avatar{
        margin-top: 0px !important;
        width: 80px;
        height: 80px;
      }
      h1{
        margin-top: 15px;
      }
      p{
        margin-bottom: 30px;
      }
    }
    .cat_single_profile_detail{
      h4{
        margin-bottom: 3px;
      }
    }
    .cat_single_nav{
      margin: 0 0 30px !important;
      ul {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 9px;
        li{
          margin-bottom: 10px;
          a{
            font-size: 14px !important;
            line-height: 28px !important;
            border-bottom: none !important;
          }
        }
      }
    }
  }
  .cat_single_greysection{
    padding-top: 30px;
  }
}

.no_horizontal_padding{
  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
}
.no_padding{
  @media (max-width: 767px) {
    padding: 0 !important;
  }
}

.icon_open{
  transform: rotate(180deg);
}

.p20{
  padding: 20px !important;
}