
.cat_single_gallery{
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
  div{
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  &.cat_single_gallery_gradient{
    div{
      &:before{
        content: "";
        position: absolute;
        width: 100%;
        height: 30%;
        bottom: 0%;
        left: 0;
        display: block;
        pointer-events: none;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        z-index: 1;
      }
    }
  }
  ion-button{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    margin-left: 15px;
    ion-icon{
      transition: all .2s ease-in-out;
    }
    &:hover{
      ion-icon{
        --ion-color-base: var(--ion-color-secondary) !important
      }
    }
  }
  .remove_image_button{
    --background-focused-opacity: 0.24;
  }
}

.modal_close_button{
  color: var(--ion-color-dark);
  --background-focused: var(--ion-color-primary);
  --background-focused-opacity: 0.12;
}