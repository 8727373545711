.my_profile_welcome_card{
  ion-card-header{
    padding: 20px 30px;
  }
  ion-card-content{
    padding: 15px 25px 25px 25px !important;
  }
  .card_footer{
    padding: 20px !important;
  }
  @media (max-width: 991px) {
    margin-left: 5px !important;
    margin-right: 5px !important;
    ion-card-header{
      padding: 20px;
    }
    ion-card-content{
      padding: 0px 20px 10px 20px !important;
    }
    .card_footer{
      padding: 20px 15px !important;
    }
  }
}

