.sucess_section_circle{
  display: block;
  background: rgba(86, 161, 77, 0.1)  ;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  position: relative;
  margin: 0 auto;
  margin-top: 60px;
  .sucess_section_icon{
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--ion-color-success);
  }
  @media (max-width: 767px){
    width: 60px;
    height: 60px;
    .sucess_section_icon{
      width: 24px;
      height: 24px;
    }
    ion-icon.sucess_section_icon{
      font-size: 24px;
    }
  }
}