.my_profile_welcome_card{
  ion-card-header{
    padding: 20px 30px 0;
    a{
      display: flex;
      align-items: center;
      color: var(--dark-grey);
      &:hover{
        color: var(--black);
        ion-card-title{
          color: var(--black) !important;
        }
      }
    }
  }
  ion-card-content{
    padding: 15px 25px 0px 25px !important;
    overflow: hidden;
    transition: all .3s ease-in-out;
    .my_profile_welcome_card_mainitem{
      border-top: 1px solid #EEEEEE;
      ion-avatar{
        margin: 10px auto;
        img{
          width: 100%;
        }
      }
      h4{
        white-space: nowrap;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .my_profile_welcome_card_secondaryitem{
    border-top: 1px dashed #EEEEEE;
    padding: 5px 0;
    &:last-of-type{
      border-bottom: none;
    }
    .item_state {
      ion-col:first-of-type{
        display: flex;
        align-items: center;
        justify-content: center;
      }
      ion-icon {
        font-size: 21px;
      }
      &.registered{
        ion-icon {
          color: var(--purple);
        }
      }
      &.received{
        ion-icon {
          color: var(--light-blue);
        }
      }
      &.processing{
        ion-icon {
          color: var(--ion-color-warning);
        }
      }
      &.completed{
        ion-icon {
          color: var(--ion-color-success);
        }
      }
      &.failed{
        ion-icon {
          color: var(--ion-color-danger);
        }
      }
    }
  }
  .card_footer{
    padding: 20px !important;
  }
}

.my_profile_welcome_card_empty{
  position: relative;
  overflow: visible;
  img{
    position: absolute;
    right: 30px;
    bottom: -20px;
    max-width: 180px;
  }
}


@media (max-width: 991px) {
  .my_profile_welcome_card{
    margin-right: 5px !important;
    margin-left: 5px !important;
    ion-card-header{
      padding: 10px 20px 5px;
      .message{
        margin-top: 5px;
      }
    }
    ion-card-content{
      padding: 0px 20px 10px 20px !important;
      ion-col{
        padding: 0 !important;
      }
      .my_profile_welcome_card_mainitem{
        ion-avatar{
          margin: 15px auto;
        }
      }
      .my_profile_welcome_card_secondaryitem{
        padding: 8px 0;
      }
      ion-list{
        padding-bottom: 0;
      }
    }
    .card_footer{
      padding: 20px 15px !important;
    }
  }
  .my_profile_welcome_card_empty{
    margin-right: 5px !important;
    margin-left: 5px !important;
    ion-card-header{
      padding: 20px;
    }
    ion-card-content{
      padding: 0px 20px 10px 20px !important;
      ion-col{
        padding: 0 !important;
      }
    }
    .card_footer{
      padding: 20px 15px !important;
    }
    img{
      position: relative;
      right: 0;
      margin-left: auto;
      display: block;
      margin-top: -40px;
    }
  }  
}


@media (max-width: 767px) {
  .my_profile_welcome_card_mainitem{
    ion-avatar{
      margin: 15px 0 !important;
    }
  }
}

.icon_open{
  transform: rotate(180deg);
}