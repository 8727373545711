/* -----------------------------------------------------
	DRAG/UPLOAD BLOCK
----------------------------------------------------- */
.Drag_block{
  border: 1px dashed #979797;
  border-radius: 5px;
  padding: 10px 0 25px;
  text-align: center;
  margin: 0px 8px;
  ion-button:hover{
    ion-icon{
      --ion-color-medium: --ion-color-secondary !important;
    }
  }
  .progress_content{
    ion-progress-bar{
      height: 10px;
      --buffer-background: var(--light-grey);
    }
    p{
      margin-top: 0;
    }
  }
  &.big{
    padding: 65px 0 80px;
  }
  @media (max-width: 767px) {
    margin: 0px;
    .ordrag_text{
      display: none;
    }
    &.big{
      padding: 20px 0 25px !important;
    }
  }
}