.my_orders_card{
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  ion-card-header{
    padding: 10px 20px;
    ion-avatar{
      width: 30px;
      height: 30px;
      margin-right: 10px;
      margin-left: 0;
    }
    ion-button {
      margin: 0;
      ion-icon{
        font-size: 24px;
        color: var(--medium-grey);
        margin-right: -10px;
      }
    }
  }
  ion-card-content{
    padding: 20px !important;
    border-top: 1px solid var(--light-grey);
  }
}

.report_name{
  margin: 12px 0;
  ion-img{
    margin-right: 8px;
  }
}

.item_state{
  width: 100%;
  &::part(native){
    min-height: 30px;
    max-height: 30px;
    align-items: flex-end;
  }
  .item{
    margin: 10px 0;
    max-width: 23px;
  }
  .label{
    display: flex;
    p {
      margin-right: 8px;
      margin-bottom: 0;
    }
  }
  .item_state_icon{
    font-size: 21px;
    margin-bottom: -6px;
  }
  .item_state_icon_line{
    position: absolute;
    height: 13px;
    width: 1px;
    background-color: #EEEEEE;
    bottom: -1px;
    left: 10px;
  }
  &:last-of-type{
    .item_state_icon_line{
      display: none;
    }
  }
  &.registered_active{
    ion-icon,
    .label p{
      color: var(--purple);
    }
  }
  &.received_active{
    ion-icon,
    .label p{
      color: var(--light-blue);
    }
  }
  &.processing_active{
    ion-icon,
    .label p{
      color: var(--ion-color-warning);
    }
  }
  &.completed_active{
    ion-icon,
    .label p{
      color: var(--ion-color-success);
    }
  }
  &.failed_active{
    ion-icon,
    .label p{
      color: var(--ion-color-danger);
    }
  }
  &.registered_inactive,
  &.received_inactive,
  &.processing_inactive,
  &.completed_inactive,
  &.failed_inactive{
    ion-icon,
    .label p{
      color: var(--dark-grey);
    }
  }
  &.regitered_empty,
  &.received_empty,
  &.processing_empty,
  &.completed_empty,
  &.failed_empty{
    ion-icon{
      color: var(--light-grey);
    }
    .label p{
      color: var(--dark-grey);
    }
  }
}

@media (max-width: 767px) {
  .my_orders_card{
    margin-bottom: 15px !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
    ion-card-header{
      padding: 10px 15px;
      ion-avatar{
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      ion-col{
        padding-top: 2px;
        padding-bottom: 2px;
      }
      h3{
        text-align: start !important;
        padding-left: 30px;
      }
      .my_orders_card_header_state{
        padding-left: 30px;
      }
    }
    ion-card-content{
      padding: 15px !important;
      ion-col{
        padding-bottom: 20px;
      }
    }
  }
}

@media (max-width: 991px) {
  .my_orders_card{
    h3{
      text-align: end;
    }
  }
}


.share_vets_banner{
  background-color: rgba(91, 52, 188, 0.05);
  padding: 18px 30px 12px;
  margin-top: 10px;
  text-align: left;
  border-radius: 5px;
  h6{
    text-transform: inherit !important;
  }
  @media (max-width: 991px) {
    margin: 10px 0 0 0;
    padding: 10px 20px;
  }
}

.pe_md{
  padding-right: 16px;
  @media (max-width: 767px) {
    padding-right: 0;
  }
}
.col_actions{
  display: flex;
  flex-direction: column;

  position: relative;
  min-height: 100%;
  .space {
    flex: 1;
  }
  @media (max-width: 991px) {
    min-height: auto;
    .space {
      flex: 0;
    }
    .report_actions{
      margin-top: 20px;
      position: relative;
    }
  }
  @media (max-width: 767px) {
    .space {
      flex: 0;
    }
    .report_actions{
      margin-top: 10px;
    }
  }
}

.id_tag{
  border: 1px solid var(--sad-purple);
  border-radius: 5px;
  max-width: max-content;
  padding: 1px 6px 0;
  p{
    line-height: 16px;
  }
}

.deceased {
  --border-radius: 0px;
}

.ctaDesktop{
  margin-top: 16px;
}