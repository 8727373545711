.cat_single_card_header{
  padding: 0;
  display: flex;
  align-items: center;
  ion-icon{
    width: 30px;
    font-size: 26px;
    margin: 0 10px 0 20px;
  }
}
.cat_single_card_subheader{
  padding: 20px 25px 20px 0;
  border-bottom: 1px solid #F3F5F0;
  @media (max-width: 767px) {
    border-bottom: none;
    padding: 14px 25px 0px 0;
    ion-row ion-col:last-of-type div{
      justify-content: start !important;
    }
  }
}
.card_content{
  margin-left: 60px;
  text-align: left;
  padding-left: 0!important;
  @media (max-width: 767px) {
    ion-row ion-col:first-of-type{
      order: 2;
    }
  }
}
.card_footer{
  margin-left: 60px;
  display: flex;
  justify-content: space-between;
  padding: 18px 10px 18px 0;
  border-top: 1px solid #F3F5F0;
}
.card_footer_empty{
  justify-content: flex-end !important;
}

@media (max-width: 991px) {
  .cat_single_card_header{
    margin-left: 20px;
    align-items: flex-start;
    ion-icon{
      margin-left: 0px;
      margin-right: 5px;
      margin-top: 20px;
    }
  }
  .card_content{
    margin-left: 20px;
  }
  .card_footer{
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .cat_single_card_date{
    border-top: 1px solid #F3F5F0;
    margin-left: -40px;
    padding-top: 14px;
    margin-top: 4px;
    margin-right: -31px;
  }
}