.card_cat_filters{
  box-shadow: 1px 2px 10px rgba(238, 238, 238, 0.8);
  border-radius: 5px;
  padding: 10px 20px;
  @media (max-width: 767px) {
    margin: 0;
    padding: 10px 10px;
    display: block;
  }
  ion-button.active{
    &::part(native){
      color: var(--ion-color-primary) !important;
    }
  }
  ion-select{
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    min-height: 30px;
  }
  ion-icon{
    font-size: 24px;
  }
}

ion-select::part(icon){
  display: none !important;
}

.search_bar{
  padding-inline-end: 0px;
  padding-inline-start: 0px;
  padding-top: 0;
  padding-bottom: 0;
  ion-icon{
    top: 14px !important;
  }
}

.card_filters{
  padding: 5px 20px 5px;
  @media (max-width: 767px) {
    padding: 16px;
  }
  ion-select{
    padding: 0;
    border: none;
    &::part(placeholder){
      --placeholder-color: var(--black);
    }
  }
  .card_filters_column2{
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      display: block;
    }
  }
}

.filter,
.sort{
  ion-icon{
    font-size: 24px;
  }
}