.selection_title{
  @media (max-width: 991px){
    text-align: center;
  }
}

.selection_footer{
  text-align: center;
  @media (min-width: 992px){
    text-align: right;
  }
}