/* -----------------------------------------------------
	HEADER
----------------------------------------------------- */

.top_link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -6px;
  gap: 8px;
}

.header{
   border-bottom: 1px solid var(--light-grey);
   ion-toolbar{
    margin-bottom: 16px;
   }
}
.header_center{
  ion-title{
    text-align: center !important;
  }
}
.header_left{
  ion-title{
    text-align: left;
  }
}

.header_action_left{
  ion-title{
    text-align: center;
  }
  ion-toolbar{
    position: relative;
    ion-buttons{
      position: absolute;
      left: 0;
    }
  }
}
.header_action_right{
  ion-title{
    text-align: center;
  }
  ion-toolbar{
    position: relative;
    ion-buttons{
      position: absolute;
      right: 0;
    }
  }
  @media (max-width: 991px) {
    ion-title{
      text-align: left;
    }
  }
}