.single_header{
  margin-top: 20px;
  @media (max-width: 550px) {
    margin-top: 0;
  }
  ion-col{
    padding-bottom: 0;
  }
  .single_nav{
    margin: 0;
    ul {
      margin: 0;
      padding-left: 0;
      padding-bottom: 13px;
      list-style-type: none;
      li{
        display: inline;
        button{
          background-color: transparent;
          color: var(--medium-grey);
          display: inline;
          padding: 20px 20px 14px;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          text-transform: uppercase;
          transition: border-color .15s ease-in-out, color .15s ease-in-out;
          border-bottom: 1px solid var(--light-grey);
          &.active,
          &:hover{
            border-color: var(--ion-color-primary);
            color: var(--ion-color-primary);
          }
        }
      }
    }
  }
}

.file{
  min-height: 30px !important;
}