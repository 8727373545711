/* -----------------------------------------------------
	CAT SINGLE PAGE
----------------------------------------------------- */
.cat_single{
  .cat_single_header{
    .buttons_header_top{
      margin-top: 55px;
      ion-button:hover{
        ion-icon{
          --ion-color-dark: --ion-color-secondary !important;
        }
      }
    }
    hr{
      margin: 20px 0 30px;
    }
    .cat_single_nav{
      margin: 40px 0;
      border-bottom: 1px solid var(--light-grey);
      ul {
        margin: 0;
        padding-left: 0;
        padding-bottom: 13px;
        list-style-type: none;
        li{
          display: inline;
          a{
            color: var(--medium-grey);
            display: inline;
            padding: 20px 20px 14px;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            text-transform: uppercase;
            transition: border-color .15s ease-in-out, color .15s ease-in-out;
            border-bottom: 1px solid var(--light-grey);
            &.active_item,
            &:hover{
              border-color: var(--ion-color-primary);
              color: var(--ion-color-primary);
            }
          }
        }
      }
    }
  }
  .cat_card_edit{
    box-shadow: 0px 5px 20px rgba(218, 219, 215, 0.2);
    padding: 40px 0 50px;
    overflow: visible;
    @media (max-width: 767px) {
      padding: 20px 0 10px;
      margin-top: 0;
    }
  }
  @media (max-width: 767px) {
    .cat_single_header{
      .buttons_header_top{
        margin-top: 30px;
      }
      h1{
        margin-top: 0;
        margin-bottom: 0;
      }
      hr{
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: 10px;
        margin-top: 15px;
      }
    }
    .cat_single_nav{
      ul {
        padding-left: 0;
        padding-right: 0;
        li{
          margin-bottom: 10px;
          a{
            padding: 5px 5px 14px !important;
            font-size: 14px !important;
            line-height: 28px !important;
            border-bottom: none !important;
          }
        }
      }
    }
  }
  .cat_card_edit{
    padding: 30px 0 20px;
    ion-card-content{
      padding-bottom: 0;
    }
  }
}


.modal_right {
  .modal_close_button{
    color: var(--ion-color-dark);
    --background-focused: var(--ion-color-primary);
    --background-focused-opacity: 0.12;
  }
  .modal_content{
    padding: 0px 30px 30px 30px;
    @media (max-width: 767px) {
      padding: 0px 30px 40px 30px;
    }
  }
  ion-avatar{
    width: 45px;
    height: 45px;
  }
  ion-header::after{
    display: none;
  }
  ion-title{
    padding-inline: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}