/* -----------------------------------------------------
	NAVBAR POPOVER
----------------------------------------------------- */

.content{
  padding: 0px 10px 20px;
  margin: 0;
  &:last-child{
    padding-bottom: 0;
  }
}
.workspaces{
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  transition: border .15s ease-in-out;
  h5{
    color: var(--black);
    &:hover,
    &:focus,
    &:active{
      color: var(--black);
    }
  }
  ion-avatar{
    margin-right: 10px;
  }
  &.active{
    border: 2px solid var(--ion-color-primary);
  }
  }
  hr{
    margin: 0;
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  a.logout{
    align-items: center;
    display: flex;
    ion-icon{
      margin-left: 5px;
    }
  p span{
    font-weight: 500 !important;
  }
}