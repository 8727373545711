.my_cats_card{
  margin-top: 30px;
  margin-bottom: 30px;
  ion-card-content{
    padding-left: 0;
    padding: 20px 30px !important;
  }
  ion-avatar{
    width: 80px;
    height: 80px;
  }
}

.col_title_gender{
  padding-left: 30px;
  ion-row{
    justify-content: space-between;
    ion-col{
      padding: 0 !important;
    }
  }
}

.cat_gender{
  margin-left: 20px !important;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: none !important;
}
.buttons_wrapper{
  justify-content: flex-end;
  ion-button ion-icon{
    font-size: 30px;
    --ion-color-base: #A9A9A9!important;
  }
}

@media (max-width: 767px) {
  .my_cats_card{
    margin-bottom: 15px !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
    ion-card-content{
      padding: 15px !important;
    }
    ion-avatar{
      width: 50px;
      height: 50px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}

@media (max-width: 991px) {
  .col_title_gender{
    padding-left: 0px;
  }
  .cat_gender{
    margin-left: 0px !important;
  }
  .buttons_wrapper{
    justify-content: flex-end;
  }
}

.memorial{
  background-color: #F9F9F9 !important;
  box-shadow: none !important;
  img{
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
  h2{
    color: var(--dark-grey);
  }
}