
.select{
  height: 50px;
  font-size: 16px;
  margin-top: 10px;
  @media (max-width: 767px) {
    font-size: 14px !important;
  }
  input{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
.css-1hwfws3{
  height: 50px !important;
}
.css-yk16xz-control{
  height: 50px;
  border-color: var(--light-grey) !important;
  border-radius: 5px !important;
}
.css-1pahdxg-control{
  &:hover{
    border-color: var(--light-blue) !important;
    box-shadow: 0px 0px 6px var(--ion-color-secondary);
  }
}
.css-1okebmr-indicatorSeparator{
  background-color: var(--light-grey) !important;
}
.css-g1d714-ValueContainer{
  height: 50px;
  position: initial !important;
}
.css-1uccc91-singleValue{
  top: 60%;
}
.css-b8ldur-Input{
  height: 50px;
  input{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.form input{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.css-1g6gooi{
  padding: 0 !important;
  margin: 0 !important;
  margin-top: -2px !important;
}



/* ---------------------------------- 

          NEW SELECT

--------------------------------------*/
/**
 * Main wrapper
 */
 .select-search {
  width: 100%;
  position: relative;
  font-family: 'Ubuntu';
  box-sizing: border-box;
  margin-top: 10px;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 50px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: 1px solid #EEEEEE;
  border-radius: 3px;
  outline: none;
  font-family: 'Ubuntu';
  font-size: 16px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 24px;
  -webkit-appearance: none;
  box-shadow: 0px 0px 4px transparent;
  transition: all .3s ease-in-out;
  color: var(--dark-grey);
  @media (max-width: 767px) {
    font-size: 14px;
    padding: 0 10px;
  }
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
  margin: 0;
  padding-inline-start: 0;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 40px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Ubuntu';
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  @media (max-width: 767px) {
    padding: 0 10px;
  }
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: var(--light-grey);
  border-radius: none !important;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(238, 238, 238, 0.5);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: rgba(238, 238, 238, 0.5);
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid var(--bright-purple);
  border-bottom: 1px solid var(--bright-purple);
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search .select-search__input:focus {
  border: 2px solid var(--light-blue);
  box-shadow: 0px 0px 4px var(--light-blue);
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 10000000;
  top: 50px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

/* just to test */
.select-test{
  background: white;
}