.cat_single_card_header{
  padding: 0;
  display: flex;
  align-items: center;
  ion-img{
    width: 30px;
    margin: 0 10px 0 20px;
  }
  ion-card-title{
    z-index: 10;
  }
}
.cat_single_card_subheader{
  padding: 20px 25px 20px 0;
  border-bottom: 1px solid #F3F5F0;
  @media (max-width: 767px) {
    ion-row ion-col:last-of-type div{
      justify-content: start !important;
    }
  }
}
.card_content{
  margin-left: 60px;
  text-align: left;
  padding: 20px 20px 20px 0 !important;
  ion-button{
    display: table;
    margin-left: 0;
  }
  ion-col:first-of-type{
    padding-left: 0;
    padding-right: 10px;
  }
}
.card_content_button{
  text-align: right;
  ion-button{
    margin-left: auto;
    margin-right: 0;
  }
}
.card_footer{
  margin-left: 60px;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 10px 0;
  border-top: 1px solid #F3F5F0;
}

/* Empty state */
.cat_single_card_header_empty{
  ion-img{
    margin: 15px 10px 0 20px;
  }
}
.cat_single_card_subheader_empty{
  border: none;
  padding: 15px 25px 0px 0;
}
.card_content_empty{
  padding-left: 0!important;
  padding-top: 0 !important;
  p{
    margin-bottom: 20px;
  }
  ion-button:first-of-type{
    margin-right: 15px;
  }
  ion-img{
    margin-top: -43px;
  }
}

@media (max-width: 991px) {
  .cat_single_card_header{
    ion-img{
      margin-left: 10px;
      margin-right: 5px;
    }
  }
  .card_content{
    margin-left: 45px;
  }
  .card_content_button{
    text-align: left;
    padding-left: 0 !important;
    ion-button{
      margin-right: auto;
      margin-left: 0;
    }
  }
}

@media (max-width: 767px) {
  .card_content_empty{
    ion-img{
      margin-top: 0px;
    }
  }
}