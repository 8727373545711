/* -----------------------------------------------------
	CAT SINGLE PAGE
----------------------------------------------------- */
.cat_single{
  .new_header_back{
    border-bottom: 1px solid var(--light-grey);
    .buttons_header_top{
      margin-top: 32px;
      ion-button:hover{
        ion-icon{
          --ion-color-dark: --ion-color-secondary !important;
        }
      }
    }
    h1{
      margin-top: -65px;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .form_card{
    box-shadow: 0px 5px 20px rgba(218, 219, 215, 0.2);
    padding: 50px 0 60px;
    overflow: visible;
  }
  @media (max-width: 767px) {
    .new_header_back{
      h1{
        margin-top: 0px;
        width: 100%;
      }
      .buttons_header_top{
        margin-top: 0px;
      }
    }
    .form_card{
      padding: 20px 0;
      margin-top: 20px;
    }
  }
}

.modal_close_button{
  color: var(--ion-color-dark);
  --background-focused: var(--ion-color-primary);
  --background-focused-opacity: 0.12;
}
.modal_content{
  padding: 15px 30px 30px 30px;
}
ion-header::after{
  display: none;
}
ion-title{
  padding-inline: 30px;
  margin-top: 20px;
}