.toolbar_button{
  margin-bottom: -30px !important;
  @media (max-width: 767px) {
    margin-bottom: 0px !important;
  }
}


.empty_state{
  p{
    margin-top: 15px;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      margin-top: 5px;
      margin-bottom: 20px;
    }
  }
}
