.cat_single_card_header{
  padding: 0;
  display: flex;
  align-items: center;
  ion-img{
    width: 30px;
    margin: 0 10px 0 20px;
  }
}
.cat_single_card_subheader{
  padding: 20px 25px 20px 0;
  border-bottom: 1px solid #F3F5F0;
  @media (max-width: 767px) {
    border-bottom: none;
    padding: 14px 25px 0px 0;
    ion-row ion-col:last-of-type div{
      justify-content: start !important;
    }
  }
}
.card_content{
  margin-left: 60px;
  text-align: left;
  padding: 20px 20px 20px 0 !important;
  ion-button{
    display: table;
    margin-left: 0;
  }
  ion-col{
    padding-left: 0;
    padding-right: 10px;
  }
}
.card_content_button{
  text-align: right;
  ion-button{
    margin-left: auto;
    margin-right: -15px
  }
}
.card_footer{
  margin-left: 60px;
  display: block;
  padding: 10px 10px 10px 0;
  border-top: 1px solid #F3F5F0;
}

/* Empty state */
.cat_single_card_header_empty{
  ion-img{
    margin: 15px 10px 0 20px;
  }
}
.cat_single_card_subheader_empty{
  border: none;
  padding: 15px 25px 0px 0;
}
.card_content_empty{
  padding-left: 0!important;
  padding-top: 0 !important;
  p{
    margin-bottom: 20px;
  }
  ion-button:first-of-type{
    margin-right: 15px;
  }
  ion-img{
    margin-top: -43px;
  }
}

@media (max-width: 991px) {
  .cat_single_card_header{
    margin-left: 20px;
    align-items: flex-start;
    ion-img{
      margin-left: 0px;
      margin-right: 5px;
      margin-top: 20px;
    }
  }
  .card_content{
    margin-left: 20px;
  }
  .card_content_button{
    text-align: left;
    padding-left: 0 !important;
    ion-button{
      margin-left: 0;
    }
  }
  .card_footer{
    margin-left: 20px;
    border-top: none;
  }
}

@media (max-width: 767px) {
  .card_content_empty{
    ion-img{
      margin-top: 0px;
      max-width: 136px;
      margin-left: auto;
    }
    p{
      margin-bottom: 0;
    }
  }
  .cat_single_card_date{
    border-top: 1px solid #F3F5F0;
    margin-left: -40px;
    padding-top: 14px;
    margin-top: 4px;
    margin-right: -31px;
  }
}

.share_vets_banner{
  background-color: rgba(91, 52, 188, 0.05);
  padding: 18px 30px 12px 40px;
  margin-left: -40px;
  margin-top: 10px;
  text-align: left;
  border-radius: 5px;
  h6{
    text-transform: inherit !important;
  }
  @media (max-width: 991px) {
    margin: 10px 0 0 0;
    padding: 10px 20px;
  }
}