ion-button.back_button{
  &::part(native){
    padding-right: 16px;
    padding-left: 16px;
  }
  @media (max-width: 767px) {
    &::part(native){
      padding-right: 12px;
      padding-left: 12px;
    }
  }
}