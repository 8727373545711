/* -----------------------------------------------------
	CAT SINGLE PAGE
----------------------------------------------------- */
.cat_single{
  .cat_single_header{
    .buttons_header_top{
      margin-top: 32px;
      ion-button:hover{
        ion-icon{
          --ion-color-dark: --ion-color-secondary !important;
        }
      }
    }
    h1{
      margin-bottom: 0;
    }
  }
  .cat_card_edit{
    box-shadow: 0px 5px 20px rgba(218, 219, 215, 0.2);
    padding: 40px 0 50px;
    @media (max-width: 767px) {
      padding: 20px 0 10px;
      margin-top: 0;
    }
  }
  @media (max-width: 767px) {
    .cat_single_header{
      .buttons_header_top{
        margin-top: 0;
      }
    }
  }
}