.my_orders_advertising_card{
  overflow: visible;
  background: var(--light-blue);
  margin: 50px 0 30px !important;
  color: white;
  display: flex;
  justify-content: space-between;
  min-height: 120px;
  margin-top: 50px;
  ion-card-content{
    width: 100%;
    position: relative;
    min-height: 80px;
    padding: 25px;
    img{
      max-width: 360px;
      top: -40px;
      left: 0px;
      position: absolute;
      z-index: 10;
      bottom: 0;
      width: 100%;
    }
    ion-row ion-col:last-of-type{
      text-align: right;
    }
    ion-button:hover{
      &::part(native){
        background-color: var(--ion-color-danger) !important;
      }
    }
  }
}

@media (max-width: 991px) {
  .my_orders_advertising_card{
    margin-top: 15px;
    margin-bottom: 15px;
    ion-card-content{
      img{
        position: relative;
        max-width: 260px;
        left: -30px;
        margin-top: -40px;
        top: 0;
      }
      ion-row ion-col:last-of-type{
        text-align: left;
      }
    }
  }
}

