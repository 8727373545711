.my_profile_welcome_card{
  background-color: var(--bright-purple);
  min-height: 257px;
  img{
    position: absolute;
    right: 0px;
    top: 20px;
    max-width: 240px;
  }
  ion-card-header{
    padding: 20px 30px;
  }
  ion-card-content{
    padding: 15px 25px 0px 25px !important;
    position: relative;
  }
  .card_footer{
    padding: 10px 20px 20px 20px !important;
  }
  @media (max-width: 1024px) {
    img{
      right: -20px;
    }
  }
  @media (max-width: 550px) {
    img{
      margin-right: -10px;
      margin-top: -160px !important;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 991px) {
    margin-right: 5px !important;
    margin-left: 5px !important;
    ion-card-header{
      padding: 20px;
    }
    ion-card-content{
      padding: 0px 20px 10px 20px !important;
      ion-col{
        padding: 0 !important;
      }
    }
    .card_footer{
      padding: 20px 15px !important;
    }
    img{
      position: relative;
      right: 0;
      left: 0;
      margin-top: -210px;
      display: block;
      margin-left: auto;
    }
  }
}
