
.message_banner{
  padding: 18px;
  background-color: rgba(91, 52, 188, 0.1);
  @media (max-width:768px) {
    margin: 0 10px;
  }
  p{
    margin: 0;
  }
  span{
    color: var(--bright-purple);
    font-weight: bold;
  }
}